<!-- TermsModal.vue -->
<template>

  <div v-if="showModal" class="modal " style="flex-direction: column; /* Zabezpečí, že deti budú pod sebou */">
    <RotatingDiv class="modal-content sklo" style="background-color: rgb(255 255 255 / 62%) !important; color: #151b27 !important;">
      <h2><i class="fa fa-lock" aria-hidden="true"></i> Požadovaný súhlas</h2>
      <p>Ahoj {{ menoje }} . Pre pokračovanie do nášho task manažéru je potrebné potvrdiť, že ste si prečítali, porozumeli a súhlasíte s našimi
        <router-link to="/obchodne" replace style="color: #b0003b">Obchodnými podmienkami</router-link> (platné od 01. 11. 2024)</p>

      <md-button @click="agreeToTerms" class="log-btn"><i class="fa fa-check" aria-hidden="true"></i> Súhlasím</md-button>

    </RotatingDiv>
    <div v-if="htmlContent" v-html="htmlContent" style="    max-width: 500px;
    width: 90%;"></div>
  </div>
</template>

<script>
import RotatingDiv from "@/components/RotatingDiv.vue";
import store from "@/store";
import axios from "axios";

export default {
  components: {
    RotatingDiv
  },
  data() {
    return {
      showModal: true,
      menoje: store.getters.menosje,
      htmlContent: null,
      cooldown: 5 * 60 * 1000 // 10 minút v milisekundách
    };
  },
  methods: {
    agreeToTerms() {
      localStorage.setItem('agreedToTerms', 'true');
      localStorage.setItem('agreedTimestamp', Date.now().toString());
      this.showModal = false;
    },
    async fetchHtmlContent() {
      const response = await axios.get('https://sla.giru.sk/oznam.php', {
        headers: {
          'Content-Type': 'text/html'
        }
      });
      this.htmlContent = response.data;
    },
    checkIfShowModal() {
      const agreed = localStorage.getItem('agreedToTerms') === 'true';
      const agreedTimestamp = parseInt(localStorage.getItem('agreedTimestamp'), 10);

      if (agreed && !isNaN(agreedTimestamp)) {
        const now = Date.now();
        const diff = now - agreedTimestamp;
console.log(diff);
        // Skontrolujeme, či od posledného súhlasu ubehlo aspoň 10 minút
        if (diff < this.cooldown) {
          // Neubehlo 10 minút, modal sa nezobrazí
          this.showModal = false;
        } else {
          // Ubehlo 10 minút, znova zobrazíme modal a vyresetujeme agreedToTerms
          localStorage.removeItem('agreedToTerms');
          localStorage.removeItem('agreedTimestamp');
          this.showModal = true;
        }
      }
    }
  },
  created() {
    this.checkIfShowModal();
    this.fetchHtmlContent();
  }
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.modal-content {
  background: rgba(217, 217, 217, 0.85);
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  text-align: center;
  color: black;
  box-shadow: 0 0 10px #636363;
}
.md-button {
  margin: auto !important;
  background-color: #0a891e !important;
  background: #0a891e !important;
  color: white !important;
}
</style>