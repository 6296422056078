<template>
  <div id="ObchodneCompoenent"   v-if="authenticated">
    <div id="dash">

      <h2><i class="fa fa-gavel" aria-hidden="true"></i> Obchodné podmienky</h2>
      <md-card class="infois">

        Všeobecné podmienky poskytovania devolperských služieb (tvorby webových stánok) a ďalších súvisiacich služieb (ďalej len „VOP“) spoločnosti GIRU s.r.o. IČO: 47 385 341, DIČ: 2023862236, IČ DPH: SK2023862236 sídlo: Kružlov 295, 086 04 Kružlov, zapísanej v Obchodnom registri Okresného súdu Prešov, odd.: Sro, vložka č.  28699/P
        <h3>1. Všeobecné ustanovenia</h3>
        <em>1.1.</em> Spoločnosť GIRU s.r.o. IČO: 47 385 341, DIČ: 2023862236, IČ DPH: SK2023862236 sídlo: Kružlov 295, 086 04 Kružlov, zapísanej v Obchodnom registri Okresného súdu Prešov, odd.: Sro, vložka č.  28699/P (ďalej len „Poskytovateľ“), kontaktný e-mail: info@giru.sk, kontaktné telefónne číslo: +421 948 633 959, je poskytovateľom služieb tvorby web stránok, aktualizácie web stránok, programovania, on-line marketingu, webdizajnu, optimalizácie pre vyhľadávače a ďalších súvisiacich služieb (ďalej jednotlivo tiež len ako „Služba“ alebo spolu ako „Služby“) podľa predmetov podnikania zapísaných v obchodnom registri.
        <br/>
        <em>1.2.</em> „Objednávateľ“ - spoločnosť, ktorá ma prístup do sla.giru.sk. Prístup je vytvorený Poskytovateľom s jedinečným menom a heslom. Objendávateľ pre zmenu prihlasovacích údajov musí kontaktovať Poskytovateľa. Tak sa zaručuje dôverihodnosť zadávania úloh, teda, že dané úlohy zadal Objednávateľ.

        <br/>
        <em>1.3.</em> Pojmy a výrazy definované alebo použité v týchto VOP sa použijú a majú zhodný význam vo všetkých záväzkových právnych vzťahoch medzi Poskytovateľom a Objednávateľom, týkajúcich sa Služieb, pokiaľ nie je všeobecne záväzným právnym predpisom ustanovené alebo zmluvnými stranami výslovne písomne dojednané inak.
      <br/>
        <em>1.4.</em> Pridaním úlohy (ďalej len „Task“) Objednávateľom alebo začatím používania tohto systému Objednávateľ potvrdzuje, že sa oboznámil so znením týchto VOP a reklamačným poriadkom Poskytovateľa.

        <h3>2. Popis Služieb</h3>
        <em>2.1.</em> <strong>Služba „Tvorba webovej stránky“</strong> je služba pri ktorej Poskytovateĺ vytvára webovú stránku (ďalej len „Dielo“) od začiatku až po úplné odovzdanie diela Objednávateľovi. Táto služba sa poskytuje na základe individuálnych požiadaviek Objednávateľa spravidla podľa osobitne uzatvorenej písomnej zmluvy s Objednávateľom. V prípade, že nedošlo k uzatvoreniu písomnej zmluvy, použijú sa na takýto zmluvný vzťah primerane ustanovenia týchto VOP.
        <br/>
        <em>2.2.</em> <strong>Služba „Obsahová aktualizácia web stránky“</strong>  je služba, kedy Poskytovateľ upravuje len určitú časť (funkcionalitu, alebo obsahovú) webovej stránky. Táto služba sa poskytuje na základe individuálnych požiadaviek Objednávateľa spravidla podľa osobitne uzatvorenej písomnej zmluvy s Objednávateľom. V prípade, že nedošlo k uzatvoreniu písomnej zmluvy, použijú sa na takýto zmluvný vzťah primerane ustanovenia týchto VOP.
        <br/>
        <em>2.3.</em> <strong>Služba „On-line marketing“, „Webdizajn“, „Optimalizácia pre vyhľadávače“</strong> sú služby, ktoré Poskytovateľ poskytuje na základe individuálnych požiadaviek Objednávateľa, spravidla podľa osobitne uzatvorenej písomnej zmluvy s Objednávateľom. V prípade, že nedošlo k uzatvoreniu písomnej zmluvy, použijú sa na takýto zmluvný vzťah primerane ustanovenia týchto VOP.
        <h3>3. Definícia pojmov a princíp zadavania úloh</h3>
        <em>3.1.</em>
        <strong>„Balíček “Basic“</strong> – je to druh balíčka, kedy medzi Zadávateľom / Objednávateľom úloh a GIRU s.r.o. nie je zmluva a riadia sa týmito obchodnými podmienkami
<br/>
        <strong>„Balíček “SLA klient“</strong> – je to druh balíčka, kedy medzi Zadávateľom / Objednávateľom úloh a GIRU s.r.o. je zmluva.
<br/>
        <strong>„Softvér“</strong> – znamená počítačový program alebo definičný skript. Napríklad CMS Wordpress, alebo Prestashop je open-source softvér.
<br/>
        <strong>„Task“</strong> – úloha ktorú zadáva Objednávateľ do tohto systému.
        <br/>
        <strong>„Update“</strong> - aktualizácia - prechod na vyššiu (stabilnú) verziu softvéru (build s vyšším minor číslom); napríklad vo Wordress 5 prechod z verzie (build) 5.1 na 5.2; zvyčajne sú aj odstránené chyby predchádzajúcej verzie pomocou patch (záplata).
        <br/>
          <strong>„Upgrade“</strong> - aktualizácia  - prechod na novú (podstatne odlišnú) verziu softvéru (build s vyšším major číslom); napríklad upgrade systému Prestashop 1.5  na Prestashop 1.6.
        <br/>
         <strong>„Inovačná verzia Softvéru“</strong> – znamená Update alebo Upgrade
        <br/>
        <strong>„Porucha“</strong> – označuje taký stav, kedy Softvér vykazuje nefunkčné vlastnosti, ktoré obmedzujú používateľa v prevádzkovaní Softvéru.
        <br/>
         <strong>„Kritická porucha“</strong> – označuje taký stav, kedy Softvér vykazuje nefunkčné vlastnosti, ktoré zabraňujú celkovému chodu softvéru.
        <br/>
        <strong>„Hlásenie porúch“</strong> – znamená papierový, alebo elektronický formulár slúžiaci pre nahlásenie Poruchy, ktorý je zaslaný na adresu technickej podpory Poskytovateľa. V prípade elektronického hlásenia poruchy, je na to určený Softvér Poskytovateľa na webovej adrese https://sla.giru.sk. Pokiaľ by došlo k výpadku elektronického podania, Objednávateľ využije papierovú formu.
        <br/>
          <strong>„Zmena“</strong> – znamená úpravu, ktorá však nezasahuje do licenčných práv tretích strán, existujúcich funkčných vlastností Softvéru.
        <br/>
            <strong>„Rozvojová úloha“</strong> – znamená realizáciu nových funkčných vlastností Softvéru.
        <br/>
              <strong>„Hlásenie požiadavky“ </strong>– znamená papierový, alebo elektronický formulár slúžiaci pre nahlásenie Požiadavky, ktorý je zaslaný na adresu technickej podpory Poskytovateľa.  V prípade elektronického hlásenia poruchy, je na to určený Softvér Poskytovateľa na webovej adrese https://sla.giru.sk. Pokiaľ by došlo k výpadku elektrického podania, Objednávateľ využije papierovú formu.
        <br/>
                <strong>„Špecifikácia požiadavky, alebo poruchy“</strong> – je presné definovanie stavu a časti softvéru, ktorého sa požiadavka, alebo porucha týka.
        <br/>
                  <strong> „Termín nahlásenia“ </strong>-  znamená dátum a čas kedy bola požiadavka, alebo porucha nahlásená Objednávateľom.
        <br/>

                  <strong>„Náhrada riešenia“</strong> – znamená to obojstranne, teda Objednávateľom aj Poskytovateľom, prijaté riešenie na odstránenie poruchy.
        <br/>
                  <strong> „Lehota pre nástup“</strong> – znamená časový interval, ktorý uplynie od Termínu nahlásenia do okamžiku začatia riešenia poruchy alebo požiadavky.
        <br/>

        <em>3.2.</em> <strong>Zadavanie taskov/úloh</strong> – Hlásenie poruch, požiadaviek sa zadávajú cez Softvér Poskytovateľa na webovej adrese https://sla.giru.sk v sekcii aktívne tasky
        <br/><em>3.3.</em> <strong>Názov Tasku/Úlohy</strong> – Časť v ktorej Objednávateľ popíše task v krátkosti.
        <br/><em>3.4.</em> <strong>Širší popis tasku</strong> – Časť v ktorej Objednávateľ popíše task do podrobna a trefne.
        <br/><em>3.5.</em> <strong>Komentáre</strong> – Každý task je možné komentovať oboma stranami
        <br/><em>3.6.</em> <strong>Nábvrh na uzatvorenie</strong> – Pokiaľ Objednávateľ neodpovedá na komentovanie a na požiadavku na kontrolu, je možné požiadať ho o uzatvorenie tasku
        <br/><em>3.7.</em> <strong>Uzatvorenie Objednávateľ</strong> – jedná sa o poslednú akciu Objednávateľa po vyriešení poruchy, update, či upgrade systému, alebo aplikácii Zmeny alebo Rozvojovej úlohy. Konkrétne je to jej uzatvorenie, teda prehlásenie Objednávateľa, že si všetko skontroloval a systém je plne funkčný. Pokiaľ Požiadavka, alebo Porucha bola nahlásená papierovou formou, uzatvorenie prebieha emailovou komunikáciou. Pokiaľ k nahláseniu došlo elektronicky v rámci softvéru Poskytovateľa na webovej stránke https://sla.giru.sk, tak aj v tomto prostredí Objednávateľ uzavrie danú Požiadavku, respektíve Úlohu.
        <br/><em>3.7.</em> <strong>Automatické uzatvorenie</strong> – Pokiaľ Objednávateľ neraguje na výzvu na uzatvorenie tasku, alebo jeho komentovanie. Task sa automaticky uzatvorí. Takýto task nie je možné reklamovať, kedže dedošlo k súčinnosti Objednávateľom.


        <h3>4. Cena</h3>

        <em>4.1.</em> Cena predmetu tejto zmluvy je dohodnutá v súlade so zákonom č. 18/1996 zz. o cenách , v znení neskorších zmien a doplnení.
       <br/>
        <em>4.2.</em> Cena za každú začatú hodinu pre Objednávateľa bez predplatených hodín v pracovnom čase (Po - Pia: 8:00 do 16:00) je 40€ bez DPH (Balíček “Basic“)<br/>
        <em>4.3.</em> Cena za každú začatú hodinu pre Objednávateľa bez predplatených hodín v pracovnom čase (Po - Pia: 8:00 do 16:00) (druh tasku: ASAP - hlásenie požiadavky) je 60€ bez DPH (Balíček “Basic“)<br/>
        <em>4.4.</em> Cena za každú začatú hodinu pre Objednávateľa mimo pracovného času (Po - Pia: 8:00 do 16:00), Sviatky, Víkend je 80€ bez DPH (Balíček “Basic“) <br/>
        <em>4.5.</em> Cena za každú začatú hodinu pre Objednávateľa s predplatenými hodinamy sa na základe individuálnych požiadaviek Objednávateľa spravidla podľa osobitne uzatvorenej písomnej zmluvy s Poskytovateľom. (Balíček “SLA klient“)


        <h3>5. Predplatené hodiny</h3>

        <em>5.1.</em> Objednávateľ si môže predplatiť hodiny v rámci balíčka “SLA klient“ a to minimámlne 10 hodín.

        <h3>6. Balíček “Basic“</h3>
        <em>6.1.</em> Predplatené hodiny: Nemá <br/>
        <em>6.2.</em> Počet taskov/úloh: Neobmedzene <br/>
        <em>6.3.</em> Počet aktívnych taskov/úloh, na ktorých sa pracuje v jednu dobu od začatia po uzatvorenie: 1 <br/>
        <em>6.4.</em> Lehota pre nástup / Pri poruche: Lehota na násup nie je definovaná a záleží na poradí Taskov/Úloh ktoré sú rozpracované od rôznych klientov. <br/>
        <em>6.5.</em> Lehota pre nástup / Realizácia Zmien a Rozvojových úloh: Lehota na násup nie je definovaná a záleží na poradí Taskov/Úloh ktoré sú rozpracované od rôznych klientov. <br/>
        <em>6.6.</em> Lehota pre nástup / Update a Upgrade systému – inovácia systému: Lehota na násup nie je definovaná a záleží na poradí Taskov/Úloh ktoré sú rozpracované od rôznych klientov. <br/>
        <em>6.7.</em> Pri druhu tasku "ASAP - hlásenie požiadavky" -  Lehota pre nástup / Update a Upgrade systému – inovácia systému: do 48 hodín (podľa bodu 4.3). Pokiaľ klient zadá viacero požiadaviek tohto druhu. Stále sa pracuje iba na 1, podľa bodu 6.3<br/>

        <h3>7. Balíček “SLA klient“</h3>
        <em>7.1.</em> Predplatené hodiny: min 10 hod. <br/>
        <em>7.2.</em> Počet taskov/úloh: Neobmedzene <br/>
        <em>7.3.</em> Počet aktívnych taskov/úloh, na ktorých sa pracuje v jednu domu od začatia po uzatvorenie: Neobmedzene <br/>
        <em>7.4.</em> Lehota pre nástup / Pri poruche: do 24 hodín<br/>
        <em>7.5.</em> Lehota pre nástup / Realizácia Zmien a Rozvojových úloh: do 3 dní <br/>
        <em>7.6.</em> Lehota pre nástup / Update a Upgrade systému – inovácia systému: do 1 mesiaca <br/>

        <h3>8. Platobné podmienky</h3>
        <em>8.1.</em> Balíček “Basic“: Faktúra je vytvorená v posledný deň v mesiaci, kedy boli ukončené úlohy Objendávateľa<br/>
        <em>8.2.</em> Balíček “Basic“ (druh tasku: ASAP - hlásenie požiadavky): Faktúra je vytvorená po dokončení úlohy <br/>
        <em>8.3.</em> Splatnosť faktúry je 15 dní od dátumu doručenia.<br/>
        <em>8.4.</em>  Faktúra bude mať náležitosti daňového dokladu. V prípade, že tieto náležitosti budú absentovať, Objednávateľ si vyhradzuje právo vrátiť takýto doklad na prepracovanie. V takomto prípade neplynie lehota na uplatnenie si úroku z omeškania.
        <br/> <em>8.5.</em> Faktúra bude doručená Objednávateľovi elektronicky (emailom). Objednávateľ dáva Poskytovateľovi súhlas na elektronické zasielanie faktúr v zmysle zákona č. 222/2004 Z. z. o dani z pridanej hodnoty v znení neskorších predpisov.
        <br/> <em>8.6.</em> Vierohodnosť dokumentu bude zaručená zaslaním faktúry z emailovej adresy Poskytovateľa: info@giru.sk,alebo objednavky@giru.sk
        <br/> <em>8.7.</em> Balíček “SLA klient“: Faktúra je vytvorená základe  zmluvy medzi Objendávateľom a Poskytovateľom<br/>
        <h3>9. Reklamácie</h3>
        <em>9.1.</em> Objednávateľ môže tasky reklamovať v Softvéry Poskytovateľa na webovej adrese https://sla.giru.sk, alebo emailom z emailu, ktorým Objednácvateľ komunikuje s Obejdnávateľom.<br/>
        <em>9.2.</em> Reklamácia musí obsahovať ID tasku, ktorý sa reklamuje a dôvod reklamácie.<br/>
        <em>9.3.</em> Pokiaľ reklamácia nebude uznaná, Objednávateľ si objednáva opravu u Poskytovateľa.<br/>
        <h3>10. Zodpovednosť za škodu a sankcie</h3>
        <em>10.1.</em> Zmluvné strany sa dohodli, že v prípade budúcich možných zmien technických, prevádzkových a/alebo právnych podmienok, ktoré ovplyvňujú, alebo budú ovplyvňovať funkčnosť a bezvadnosť výsledku servisných prác (napr. zmena podmienok pre funkcie prehliadačov a iné zmeny v riešeniach s dopadom na využitie výsledku servisu, zmena legislatívy, zmena hostingových parametrov a všetok softvér tretích strán, ktoré Objednávateľ využíva), ktoré v čase uzatvorenia Zmluvy, nebolo možné Poskytovateľom pri zachovaní náležitej starostlivosti a odbornej spôsobilosti predvídať (ďalej len „Nepredvídateľné okolnosti“), nebudú tieto Nepredvídateľné okolnosti zakladať zodpovednosť Poskytovateľa za nefunkčnosť výsledkov Servisu v čase vzniku týchto okolností a ich prípadný výskyt v budúcnosti nezakladá právo Objednávateľa, reklamovať tieto skutočnosti  u Poskytovateľa ako vady vykonaného servisu.
        <br/>
        <h3>11. Mlčanlivosť</h3>
        <em>11.1.</em> Objednávateľ a Poskytovateľ budú spolupracovať, alebo v čase podpisu Zmluvy už reálne spolupracujú na činnostiach, pri ktorých Poskytovateľ môže prísť do styku s osobnými údajmi u Objednávateľa informácií v zmysle Nariadenia Európskeho Parlamentu a Rady (EÚ) 2016/679 z 27. apríla 2016 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov, ktorým sa zrušuje smernica 95/46/ES (všeobecné nariadenie o ochrane údajov) (ďalej len „nariadenie GDPR“) a zákona č. 18/2018 Z. z. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov (ďalej len ,,Zákon“). Vyššie uvedené činnosti v oblasti ochrany osobný údajov Poskytovateľ vykonáva na základe špecifikácie, ktorú mu Objednávateľ zaslal a/alebo osobne vysvetlil.
        <br/><em>11.2</em> Dôverné informácie - Za dôverné sa považujú informácie o osobe Objednávateľa, jeho obchodných partneroch a o akýchkoľvek postupoch, plánovaných alebo uskutočnených krokoch, záveroch, dohodách, zmluvách, listinách, dokumentoch, dojednaniach, rozhovoroch (vrátane ich obsahu), obchodnej politike, hospodárskych výsledkoch, technických detailoch, plánoch, postupoch, nápadoch alebo o akýchkoľvek iných úkonoch, o ktorých bude mať Poskytovateľ vedomosť, vrátane informácii o Úlohe samotnom. Ďalej sa za dôverné považujú všetky informácie obsiahnuté v materiáloch a podkladoch, ktoré Poskytovateľ  získal od Objednávateľa informácií v akejkoľvek podobe, vrátane ich fyzických nosičov. Poskytovateľ súhlasí, že všetky návrhy, vyjadrenia, kalkulácie, materiály, podklady a iné diela ktoré vytvoril v priebehu a na účel Spolupráce sa taktiež považujú za dôverné.
        <br/><em>11.3.</em> Záväzok mlčanlivosti. - Zmluvné strany sa dohodli, že realizácia Úlohy a všetky kroky a informácie súvisiace s naplnením Úlohu ostanú neverejné a utajené. Poskytovateľ sa zaväzuje, že bude zachovávať mlčanlivosť o všetkých Dôverných informáciách a iných informáciách podľa tejto Zmluvy. Poskytovateľ sa zaväzuje, že Dôverné informácie neprezradí resp. ich nesprístupní tretej osobe a ani ich nepoužije v rozpore s ich účelom pre svoje potreby a ani potreby tretej osoby, pokiaľ k takémuto konaniu nebude mať výslovný písomný súhlas Poskytovateľa informácií a to ani v prípade, že nedôjde k uskutočneniu Úlohu alebo bude Spolupráca predčasne ukončená, alebo k nej v konečnom dôsledku nedôjde. Poskytovateľ sa taktiež zaväzuje všetkými jemu dostupnými spôsobmi zabrániť možnosti prezradenia Dôverných informácií.
        <br/> <em>11.4.</em> Poskytovateľ sa v prípade, že bude zo strany tretej osoby vyzvaný na poskytnutie Dôverných informácií, zaväzuje bezodkladne informovať Objednávateľa o tejto skutočnosti. Poskytovateľ  je oprávnený Dôverné informácie alebo ich časť poskytnúť akejkoľvek tretej osobe s ktorou má podpísanú zmluvu o mlčanlovosti. Poskytovateľ sa zaväzuje, že počas trvania Spolupráce pri realizácií Úlohu a ani po realizácií Úlohy a aj v prípade, ak nedôjde k úspešnej realizácii Úlohu, bude zachovávať mlčanlivosť o všetkých skutočnostiach, ktoré sa dozvedel alebo o ktorých sa dozvedel v súvislosti s realizáciou Úlohu. Mlčanlivosťou sa na účely tejto Zmluvy rozumie aj zákaz manipulácie s Dôvernými informáciami v inej, než v miere nevyhnutnej pre potreby Prijímateľa informácií pre vykonávanie Spolupráce. Prijímateľ informácií sa rovnako zaväzuje zachovávať mlčanlivosť aj o osobách, ktoré budú vystupovať v procese realizácie Úlohu, o ktorých súčasne bude mať vedomosť. Súčasťou záväzku mlčanlivosti je zákaz rozširovania informácií o existencii Úlohu. Prijímateľ informácií sa zaväzuje na žiadosť Zadávateľa vrátiť všetky získané podklady a materiály vrátane ich fyzických nosičov a ich digitálnu podobu (napr. e-maily a súbory v digitálnej podobe) bezpečne zničiť resp. vymazať.
        <h3>12. Orgán dozoru</h3>
        <em>12.1.</em> Inšpektorát SOI pre Prešovský kraj<br/>
        so sídlom v Prešove<br/>
        Obrancov mieru 6, 080 01  Prešov 1<br/>
        Odbor výkonu dozoru a Právny odbor<br/>
        tel. č. 051/7721 597 fax. č. 051/7721 596<br/>
        e-mail: po@soi.sk

        <h3>13. Záverečné ustanovenia</h3>
        <em>13.1.</em> Zmluva a tieto VOP sú vyhotovené a uzatvárané v slovenskom jazyku. V prípade, že sú podpísané i v cudzojazyčnom vyhotovení, je rozhodujúce toto slovenské znenie.
        <br/>
        <em>13.2.</em> Fyzická osoba, ktorá uzatvára Zmluvu v mene právnickej osoby ako Objednávateľ, zodpovedá osobne za všetky právne následky a spôsobenú škodu, pokiaľ nebola za Objednávateľa – právnickú osobu oprávnená konať alebo ak Obchodný zákonník, platný v Slovenskej republike, neustanovuje inak.
        <br/>
        <em>13.3.</em> Všeobecné podmienky nadobudli platnosť a účinnosť dňom 01. 11. 2024.
        <br/>
        </md-card>
    </div>
  </div>
</template>

<script>
import store from "../store";
export default {
  name: "ObchodneCompoenent",
  data() {
    return {
      authenticated:this.authenticated,
    }
  },
  methods:{
    setAuthenticated(status) {
      this.authenticated = status;
    },
  },
  beforeCreate() {
    var self = this;
    this.menoje = store.getters.menosje;
    this.idkoje = store.getters.idkojes;
    if(!this.menoje) {
      store.commit('ADD_IDKOS', '');
      store.commit('ADD_MENO', '');
      window.location.href = '/#/';
    }else{
      self.progresak = true;
      this.authenticated = true;

    }
  }
}
</script>

<style scoped>

.infois{
  padding: 20px;
}

h3{
  color: #d91d5e;
}

h2 .fa{
  color: #ee487d;
}

</style>